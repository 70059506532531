<template>
  <div class="news">
    <div class="news__left">
      <div class="category pc" v-if="category">
        <img src="../assets/img/logo/logo-category.svg" alt="" />
        <a class="before" href="#">Trang chủ</a>
        <span class="tag-mid">/</span>
        <a class="before" href="#">Tin Tức & Sự Kiện</a>
        <!-- <span class="tag-mid">/</span>
        <a :class="type === 0 ? 'after' : 'before'" href="#" @click="view(0)"
          >Tin Tức</a
        > -->
        <span class="tag-mid" v-if="category[locale]?.name">/</span>
        <a class="after" href="#" v-if="category[locale]?.name">{{
          category[locale]?.name
        }}</a>
      </div>
      <div class="news-main">
        <div class="news-main__title" data-aos="fade-up" v-if="category">
          <h5>{{ category[locale]?.name }}</h5>
          <h1>{{ category[locale]?.content }}</h1>
        </div>
        <div class="news-main__content" data-aos="fade-up">
          <div class="post-left" v-if="newsFist">
            <div class="item">
              <router-link
                :to="
                  '/news/' +
                  newsFist?.category?.slug +
                  '/' +
                  newsFist?.slug +
                  '?id=' +
                  newsFist.id
                "
                ><div class="item__bg">
                  <img :src="newsFist.image" alt="" /></div
              ></router-link>

              <h5 class="item__category">
                {{ newsFist?.category[locale]?.name }}
              </h5>
              <router-link
                :to="
                  '/news/' +
                  newsFist?.category.slug +
                  '/' +
                  newsFist?.slug +
                  '?id=' +
                  newsFist.id
                "
                ><h1 class="item__title">
                  {{ newsFist[locale]?.title }}
                </h1></router-link
              >
              <h1 class="item__time">{{ getDay(newsFist?.created_at) }}</h1>
              <p class="item__des">
                {{ newsFist[locale]?.description }}
              </p>
              <div class="item__view">
                <router-link
                  :to="
                    '/news/' +
                    newsFist?.category?.slug +
                    '/' +
                    newsFist?.slug +
                    '?id=' +
                    newsFist.id
                  "
                >
                  <img
                    src="../assets/img/icons/arrow-right-orange.svg"
                    alt=""
                  />
                  <span>XEM TIẾP</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="post-right" v-if="newList && newList.length > 0">
            <div class="item" v-for="(item, index) in newList" :key="index">
              <router-link
                :to="
                  '/news/' +
                  item?.category?.slug +
                  '/' +
                  item?.slug +
                  '?id=' +
                  item.id
                "
              >
                <div class="item__bg">
                  <img :src="item.image" alt="" />
                </div>
                <h5 class="item__category">
                  {{ item?.category[locale]?.name }}
                </h5>
                <h1 class="item__title">
                  {{ item[locale]?.title }}
                </h1>
                <p class="item__time">{{ getDay(item.created_at) }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="new-other">
        <div
          class="item"
          data-aos="fade-up"
          v-for="(item, index) in newOther"
          :key="index"
        >
          <router-link
            class="item-bg"
            :to="
              '/news/' + item?.category?.slug + '/' + item?.slug + '?id=' + item.id
            "
            ><div class="item__bg">
              <img :src="item.image" alt="" /></div
          ></router-link>

          <div class="item-content">
            <h5 class="item__category">{{ item?.category[locale]?.name }}</h5>
            <router-link
              class="item-bg"
              :to="
                '/news/' +
                item.category.slug +
                '/' +
                item.slug +
                '?id=' +
                item.id
              "
            >
              <h1 class="item__title">
                {{ item[locale]?.title }}
              </h1></router-link
            >
            <h1 class="item__time">{{ getDay(item?.created_at) }}</h1>
            <p class="item__des">
              {{ item[locale]?.description }}
            </p>
            <div class="item__view">
              <router-link
                class="item-bg"
                :to="
                  '/news/' +
                  item.category.slug +
                  '/' +
                  item.slug +
                  '?id=' +
                  item.id
                "
              >
                <img src="../assets/img/icons/arrow-right-orange.svg" alt="" />
                <span>XEM TIẾP</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="news__right"
      data-aos="fade-up"
      v-if="this.$route.params.slug == 'tin-tuc' || !this.$route.params.slug"
    >
      <div class="navbar">
        <ul class="navbar__menu">
          <li
            v-for="(item, index) in categorys"
            :key="index"
            :class="type === item.id ? 'active' : ''"
          >
            <router-link :to="'/news/' + item.slug" @click="view(item)">
              {{ item[locale]?.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import { mapActions, mapGetters } from "vuex";
import NewsService from "@/services/NewsService";
export default {
  data() {
    return {
      dataNews: "",
      newsFist: "",
      category: "",
      newList: [],
      newOther: [],
      type: 0,
      categorys: "",
      headMenu: {
        main: 3,
        sub: 0,
      },
    };
  },
  mounted: function () {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.$nextTick(function () {
      $(window).bind("scroll", function () {
        if (
          $(window).scrollTop() + $(window).height() >
          $("#ft-b").offset().top
        ) {
          $(".news__right").removeClass("menu-fixed");
        } else if ($(window).scrollTop() > 150) {
          $(".news__right").addClass("menu-fixed");
        } else {
          $(".news__right").removeClass("menu-fixed");
        }
      });
    });
    this.setMenu(this.headMenu);
    this.getCategories();
    this.getPageNews();
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  watch: {
    "$route.params"() {
      if (this.$route.path === "/news" || this.$route.path === "/news/tin-tuc") {
        console.log('vao');
        this.setMenu(this.headMenu);
        this.getCategories();
        this.getPageNews();
      }
    },
  },
  methods: {
    ...mapActions(["setMenu"]),
    view(item) {
      this.$router.push({ path: `/news/detail/${item.slug}` });
      this.type = item.id;
      this.getDataSlug(item.slug);
    },
    getDay(day) {
      if (day) {
        const arr = day.split("-");
        return arr[0] + " Tháng " + arr[1] + " năm " + arr[2];
      }
      return "";
    },
    async getCategories() {
      await NewsService.categories()
        .then((resp) => {
          this.categorys = resp.data.data;
        })
        .catch(() => {});
    },
    async getPageNews() {
      await NewsService.pageNews()
        .then((resp) => {
          this.dataNews = resp.data.data;
          if (this.dataNews?.news?.length > 0) {
            this.newsFist = this.dataNews.news[0];
          }
          if (this.dataNews?.news?.length > 1) {
            this.newList = this.dataNews.news.slice(1, 3);
          }
          if (this.dataNews?.news?.length > 3) {
            this.newOther = this.dataNews.news.slice(
              3,
              this.dataNews.news.length
            );
          }
          this.category = this.dataNews?.category;
        })
        .catch(() => {});
    },
    async getDataSlug(slug) {
      this.newsFist = [];
      this.newList = [];
      this.newOther = [];
      await NewsService.getNewsSlug(slug)
        .then((resp) => {
          this.dataNews = resp.data.data;
          if (this.dataNews?.length > 0) {
            this.newsFist = this.dataNews[0];
          }
          if (this.dataNews?.length > 1) {
            this.newList = this.dataNews.slice(1, 3);
          }
          if (this.dataNews?.length > 3) {
            this.newOther = this.dataNews.slice(3, this.dataNews.length);
          }
        })
        .catch(() => {});
    },
  },
};
</script>
